<template>
    <div class="card has-text-centered">
        <header class="card-header">
            <button
                type="button"
                class="modal-close is-large"
                style="position:absolute; top: 1px; right: 4px;"
                @click="$parent.close()" />
            <p class="card-header-title">{{ $t('Upload document') }}</p>
        </header>
        <div class="card-content column is-half is-offset-one-quarter">
            <div class="content">
                <br>
                <b-field position="is-centered">
                    <b-input v-model="fileName" :placeholder="$t('File name')" expanded />
                </b-field>
                <br>
                <b-field v-if="!file">
                    <b-upload v-model="file"
                              drag-drop>
                        <section class="section">
                            <div class="content has-text-centered">
                                <p>
                                    <DocumentUploadIcon />
                                </p>
                                <p class="is-size-6"><b>{{ $t('Please upload a document') }}</b></p>
                            </div>
                        </section>
                    </b-upload>
                </b-field>
                <b-message v-else type="is-success" size="is-small">
                    <p>{{ $t('Upload completed!') }}</p>
                </b-message>
                <br>
                <button class="button is-primary" :disabled="!file" @click="emitB64File">
                    <span>{{ $t('Store') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import { filesize, fileToBase64 } from '@/utils'
    import { NotificationMixin } from '@/config/mixins'
    import DocumentUploadIcon from '@/components/icons/DocumentUpload'
    import { MAX_UPLOAD_FILE_SIZE } from '@/constants'

    export default {
        components: { DocumentUploadIcon },
        mixins: [
            NotificationMixin
        ],
        data() {
            return { file: null, fileName: '' }
        },
        watch: {
            async file(file) {
                if (!file)
                    return

                if (file.type !== 'application/pdf') {
                    this.notifyError(this.$t('Only .pdf files are allowed.'))
                    this.file = null
                    return
                } else if (file.size && file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.notifyError(this.$t('File size is too big. Max allowed size is {filesize}.', { filesize: filesize(MAX_UPLOAD_FILE_SIZE) }))
                    this.file = null
                    return
                }
                this.fileName = await this.getSuggestedFilename(file.name)
            }
        },
        methods: {
            ...mapActions('documents', [ 'getSuggestedFilename' ]),
            async emitB64File() {
                try {
                    const b64File = await fileToBase64(this.file)
                    this.$emit('store', {
                        fileName: this.fileName,
                        file: b64File
                    })
                } catch (e) {
                    this.notifyError(this.$t('This file seems corrupt.'))
                }
            }
        }
    }
</script>

<style lang="scss">
    .card.has-text-centered {
        .card-header-title,
        .card-content {
            justify-content: center;
            align-items: center;
        }
    }
</style>
