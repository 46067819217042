<template>
    <div>
        <section class="section">
            <div class="container">
                <div class="columns">
                    <div class="column is-9">
                        <div class="message-like">
                            <BulbIcon />
                            {{ introtext }}
                        </div>
                    </div>
                    <div class="column is-3">
                        <button class="button is-fullwidth"
                                @click="isNewDocumentModalActive = true">
                            <span>{{ $t('Upload document') }}</span>
                        </button>
                        <div v-if="disk_quota" class="disk-quota is-pulled-right">
                            {{ disk_quota.documents|diskQuota }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <router-view />
            </div>
            <b-modal :active.sync="isNewDocumentModalActive"
                     :can-cancel="['escape', 'outside', 'button']">
                <DocumentUploadCard @store="saveDoc($event)"
                                    @cancel="isNewDocumentModalActive = false" />
            </b-modal>
        </section>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import { NotificationMixin } from '@/config/mixins'
    import DocumentUploadCard from './components/DocumentUploadCard'
    import BulbIcon from '@/components/icons/Bulb'
    import { getDiskQuota } from '@/services/company.service'


    export default {
        components: { DocumentUploadCard, BulbIcon },
        mixins: [ NotificationMixin ],
        data() {
            return {
                isNewDocumentModalActive: false,
                // eslint-disable-next-line max-len
                introtext: this.$t('Documents intro text. Penatibus mollis lectus vestibulum tempor aenean scelerisque leo id, rutrum mattis lacus ridiculus habitasse luctus vivamus, nisi molestie bibendum maximus nostra nam faucibus.'),
                disk_quota: null
            }
        },
        mounted() {
            this.fetch()
            getDiskQuota().then(r => this.disk_quota = r)
        },
        methods: {
            ...mapActions('documents', [ 'fetch', 'save' ]),
            async saveDoc(doc) {
                await this.save(doc)
                    .then(this.notifySuccess)
                    .catch(e => this.notifyError(e.response.data))
                this.isNewDocumentModalActive = false
                getDiskQuota().then(r => this.disk_quota = r)
            }
        }
    }
</script>
