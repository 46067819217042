import { render, staticRenderFns } from "./DocumentUploadCard.vue?vue&type=template&id=7f165bdf"
import script from "./DocumentUploadCard.vue?vue&type=script&lang=js"
export * from "./DocumentUploadCard.vue?vue&type=script&lang=js"
import style0 from "./DocumentUploadCard.vue?vue&type=style&index=0&id=7f165bdf&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports